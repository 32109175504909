import React, { useState, useContext, useEffect } from "react";
import { Select, Image, Box, Flex } from "@chakra-ui/react";

import qr from "../content/icons/qr.svg";
import { UserContext } from "../context/index.js";

import { useTranslation } from "react-i18next";
import { setUserClaimLang } from "../calls.js";

const LanguageOption = ({ code, name, icon }) => (
  <Flex align="center">
    <Image src={icon} alt={name} boxSize="1rem" mr={2} />
    {name}
  </Flex>
);

const LanguageSelector = ({ loginPage }) => {
  const { i18n } = useTranslation();
  const [state] = useContext(UserContext);
  const [selectedLanguage, setSelectedLanguage] = useState();

  useEffect(() => {
    if (sessionStorage.getItem("lang")) {
      setSelectedLanguage(sessionStorage.getItem("lang"));
    } else if (state.lang) {
      setSelectedLanguage(state.lang);
    }
  }, [state]);

  const languages = [
    { code: "sv", name: "Svenska", icon: qr },
    { code: "en", name: "English", icon: qr },
    // Add more languages with their respective icons
  ];

  return (
    <Select
      value={selectedLanguage}
      onChange={async (e) => {
        sessionStorage.setItem("lang", e.target.value);
        setSelectedLanguage(e.target.value);
        i18n.changeLanguage(e.target.value);
        !loginPage && (await setUserClaimLang(() => state.user.getIdToken()));
      }}
    >
      {languages.map((language) => (
        <option key={language.code} value={language.code}>
          <LanguageOption {...language} />
        </option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
