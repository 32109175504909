import { Outlet, useLocation, Navigate, useNavigate } from "react-router-dom";
import { useContext, useEffect, useState } from "react";
import { UserContext } from "../context/index.js";
import { onAuthStateChanged } from "firebase/auth";
import { auth } from "../init/firebase.js";
import { useTranslation } from "react-i18next";

import { Spinner, Flex } from "@chakra-ui/react";
import { getOwnerStripeRole } from "../calls.js";

const PrivateRoutesLayout = () => {
  const location = useLocation();
  const [state, dispatch] = useContext(UserContext);
  const [authChecked, setAuthChecked] = useState(false);
  const navigate = useNavigate();
  const { i18n } = useTranslation();
  useEffect(() => {
    let isMounted = true;

    const checkAuthUser = onAuthStateChanged(auth, async (user) => {
      try {
        if (user) {
          const userResult = await user.getIdTokenResult(true);

          if (sessionStorage.getItem("lang")) {
            i18n.changeLanguage(sessionStorage.getItem("lang"));
          } else if (userResult.claims.lang) {
            i18n.changeLanguage(userResult.claims.lang);
          }

          if (isMounted) {
            if (userResult.claims.stripeRole) {
              dispatch({
                ...state,
                user: user,
                stripeRole: userResult.claims.stripeRole,
                organization: userResult.claims.organization,
                lang: userResult.claims.lang,
              });
            } else if (userResult.claims.organization) {
              const result = await getOwnerStripeRole(() => user.getIdToken());

              dispatch({
                ...state,
                user: user,
                stripeRole: result.data.stripeRole,
                organization: userResult.claims.organization,
                lang: userResult.claims.lang,
              });
            } else {
              dispatch({
                ...state,
                user: user,
                stripeRole: userResult.claims.stripeRole,
                organization: userResult.claims.organization,
                lang: userResult.claims.lang,
              });
            }

            setAuthChecked(true);
          }
        } else {
          // Handle the case when the user is not authenticated
          if (isMounted) {
            setAuthChecked(true);
          }
        }
      } catch (error) {
        navigate("/");
      }
    });

    // Clean up the subscription when the component unmounts
    return () => {
      isMounted = false;
      checkAuthUser();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate]);

  if (!authChecked) {
    // Show a loading spinner or message while authentication state is being checked
    return (
      <Flex
        w={"100vw"}
        h={"100vh"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size={"xl"} color={"#062C8E"} />
      </Flex>
    );
  }

  return authChecked ? (
    <Outlet />
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

export default PrivateRoutesLayout;
