import { Box, Grid, Flex, Button, Text, Link, Spinner } from "@chakra-ui/react";
import WidgetWrapper from "../../../components/Widgets/WidgetWrapper";
import { InputComponent } from "../../../components/Widgets/InputComponent";
import { useEffect, useContext, useState } from "react";
import DesignConstructor from "../../../components/DesignQR";
import { UserContext } from "../../../context/index.js";
import { useNavigate } from "react-router-dom";
import { ensureProtocol } from "../../../functions/ensureProtocolUrl.js";

import SelectionWidget from "../../../components/Widgets/SelectionWidget";

import {
  generateQRID,
  createQRCode,
  checkUserAmountOfQRCodes,
  createDesignTemplate,
} from "../../../calls";
import QrGenerator from "../../../components/QrGenerator";
import { areAllValuesSet } from "../../../functions/areAllValuesSet";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

const CreateQR = () => {
  const { t } = useTranslation();

  const navigation = useNavigate();
  const [state] = useContext(UserContext);
  const [checkQRcount, setCheckQRCount] = useState();
  const [designName, setDesignName] = useState("");
  const [showDesignTemplete, setShowDesignTemplete] = useState(false);
  const [loadImage, setLoadImage] = useState(false);
  const [loading, setLoading] = useState(true);

  const [qrSettings, setqrSettings] = useState({
    targetUrl: "",
    qrName: "",
    color: "#000000",
    background: "#FEFEFE",
    eyeRadius: 0,
    qrStyle: "Squares",
    ecLevel: "L",
    qrID: "",
    tags: [],
    alert: false,
  });

  const fetchDataPromise = () => {
    return new Promise(async (resolve, reject) => {
      try {
        if (qrSettings.qrID) {
          resolve({ check: true });
        } else {
          await checkUserAmountOfQRCodes(() => state.user.getIdToken());
          setCheckQRCount({ check: true });
          resolve({ check: true });
        }
      } catch (error) {
        setCheckQRCount({ data: error.response.data, check: false });
        reject(error);
      }

      try {
        if (!qrSettings.qrID) {
          const qrID = await generateQRID(() => state.user.getIdToken());
          setqrSettings((prevSettings) => ({ ...prevSettings, qrID: qrID }));
          resolve(qrID);
        }
      } catch (error) {
        // Handle error if needed
        console.error(error);
        reject(error);
      }
    });
  };

  useEffect(() => {
    setLoading(true);
    fetchDataPromise()
      .then((result) => {
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        toast.error(error);
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [qrSettings.qrID, state.user]);

  if (loading) {
    return (
      <Flex
        height={"100%"}
        width={"100%"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Spinner size={"xl"} color={"#062C8E"}></Spinner>
      </Flex>
    );
  }
  if (!checkQRcount) {
    return;
  }

  if (!checkQRcount.check) {
    return (
      <Grid
        display={["grid"]}
        flexDirection={"column"}
        gridTemplateColumns={"1fr"}
        paddingBottom={"20px"}
        gap={"20px"}
        height={"100%"}
      >
        <WidgetWrapper
          extraTextComponoent={
            <Box>
              <Flex
                flexDirection={"column"}
                alignItems={"center"}
                textAlign={"center"}
                padding={"0px 20px"}
              >
                <Text>
                  {t("createMaximumQrText")}{" "}
                  <span style={{ fontWeight: "bold" }}>
                    ({checkQRcount.data.data.maximumCodes})
                  </span>{" "}
                  {t("forYourPaymentPlan")}{" "}
                  <span style={{ fontWeight: "bold" }}>
                    {state.stripeRole ? state.stripeRole : t("freeAccount")}
                  </span>
                </Text>
                <Text>{t("contactForMoreQR")}</Text>
              </Flex>
            </Box>
          }
          qrLimit={true}
          child={
            <Flex
              justifyContent={"center"}
              alignItems={"center"}
              width={"100%"}
            ></Flex>
          }
        />
      </Grid>
    );
  }

  return (
    <Grid
      display={["flex", "flex", "grid"]}
      flexDirection={"column"}
      color={"white"}
      gridTemplateColumns={"1fr 1fr "}
      paddingBottom={"20px"}
      gap={"20px"}
    >
      <Grid gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          blockForReadersOnly={true}
          callToAction={true}
          child={
            <Box w={"100%"}>
              <InputComponent
                toolTip={true}
                toolTipText={t("qrNameTooltip")}
                placeholder={t("qrNameExample")}
                label={t("qrNameLabel")}
                setState={setqrSettings}
                value={qrSettings}
                keyValue={"qrName"}
                type={"text"}
              />

              <InputComponent
                toolTip={true}
                toolTipText={t("qrRedirectTooltip")}
                placeholder={t("qrRedirectExample")}
                label={t("qrRedirectLabel")}
                setState={setqrSettings}
                value={qrSettings}
                keyValue={"targetUrl"}
                type={"url"}
              />

              <SelectionWidget
                toolTip={true}
                toolTipText={t("qrTagTooltip")}
                payedFeature={false}
                label={t("qrTagLabel")}
                qrSettings={qrSettings}
                setqrSettings={setqrSettings}
              />
            </Box>
          }
        />
        <WidgetWrapper
          callToAction={true}
          payedFeature={false}
          blockForReadersOnly={true}
          child={
            <DesignConstructor
              setLoadImage={setLoadImage}
              setState={setqrSettings}
              value={qrSettings}
            />
          }
        />
      </Grid>

      <Grid gap={"20px"} marginBottom={"auto"}>
        <WidgetWrapper
          center={true}
          blockForReadersOnly={true}
          child={
            <Flex
              width={"100%"}
              justifyContent={"center"}
              alignItems={"center"}
              flexDirection={"column"}
              position={"relative"}
            >
              {loadImage ? (
                <Flex alignItems={"center"} height={"230px"}>
                  <Spinner></Spinner>
                </Flex>
              ) : (
                <QrGenerator
                  loadImage={loadImage}
                  qrSettings={qrSettings}
                  setqrSettings={setqrSettings}
                />
              )}
            </Flex>
          }
        />
        <Flex flexDirection={"column"}>
          <Button
            w={"full"}
            p={8}
            bg={areAllValuesSet(qrSettings) ? "#5138EE" : "#A398EA"}
            fontSize={"18px"}
            color={"white"}
            fontWeight={"bold"}
            isDisabled={!designName && showDesignTemplete}
            onClick={async () => {
              if (!areAllValuesSet(qrSettings)) return;
              //DesignTemplate
              if (designName) {
                try {
                  await createDesignTemplate(() => state.user.getIdToken(), {
                    ...qrSettings,
                    designName: designName,
                  });

                  toast.success(
                    t("designCreateSuccess", { designName: designName })
                  );
                } catch (error) {
                  toast.error(t("designCreateError"));
                }
              }
              //Create QR
              try {
                await createQRCode(() => state.user.getIdToken(), {
                  ...qrSettings,
                  targetUrl: ensureProtocol(qrSettings.targetUrl),
                });
                toast.success(
                  t("qrCreateSuccess", { qrName: qrSettings.qrName })
                );
                navigation("/mina-qr/" + qrSettings.qrID);
              } catch (error) {
                toast.error(error.response.data.message);
              }
            }}
          >
            {t("createQR")} {showDesignTemplete && " " + t("andDesign")}
          </Button>

          {!showDesignTemplete && (
            <Link
              onClick={() => setShowDesignTemplete(true)}
              color={"#292929"}
              marginLeft={"auto"}
              fontSize={"14px"}
              marginTop={"5px"}
            >
              {t("saveDesign")}
            </Link>
          )}
          <Flex marginTop={"20px"}>
            {showDesignTemplete && (
              <WidgetWrapper
                child={
                  <InputComponent
                    setState={setDesignName}
                    value={designName}
                    underText={t("designSaveTooltip")}
                    placeholder={t("designExample")}
                    label={t("designLabel")}
                  ></InputComponent>
                }
              />
            )}
          </Flex>
          {showDesignTemplete && (
            <Link
              onClick={() => setShowDesignTemplete(false)}
              color={"#292929"}
              marginLeft={"auto"}
              fontSize={"14px"}
              marginTop={"5px"}
            >
              {t("dontSaveDesign")}
            </Link>
          )}
        </Flex>
      </Grid>
    </Grid>
  );
};

export default CreateQR;
