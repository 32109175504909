import { useRef, useEffect, useState, useContext } from "react";
import Highcharts from "highcharts";
import { UserContext } from "../context/index";
import { Spinner, Flex, Box, Text } from "@chakra-ui/react";

import {
  countTotalEventsFromBigqueryByTime,
  countEventsfromBigqueryByTime,
} from "../calls";

import { useTranslation } from "react-i18next";

const getDateTimeLabelFormats = (interval) => {
  switch (interval) {
    case "MONTH":
      return {
        month: "%b. %Y",
        day: "%b. %e, %Y",
        hour: "%b. %e, %Y %H:%M",
        minute: "%b. %e, %Y %H:%M",
      };
    case "DAY":
      return {
        day: "%b. %e, %Y",
        hour: "%b. %e, %Y %H:%M",
        minute: "%b. %e, %Y %H:%M",
      };
    case "HOUR":
      return {
        day: "%b. %e, %Y %H:00",
      };
    case "MINUTE":
      return {
        month: "%b. %Y",
        day: "%b. %e, %Y",
        hour: "%b. %e, %Y %H:%M",
        minute: "%b. %e, %Y %H:%M",
      };
    default:
      return {};
  }
};

const HighChart = ({ selectedQR, queryOptions, removeLegend }) => {
  const { t } = useTranslation();

  const [state] = useContext(UserContext);
  const [loading, setLoading] = useState(false);
  const chartRef = useRef(null);
  const [chartData, setChartData] = useState([]);
  const [containData, setContainData] = useState(false);
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  const handleResize = () => {
    setWindowSize({
      width: window.innerWidth,
      height: window.innerHeight,
    });
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (loading) return;
    if (state.stripeRole === "undefiend") {
      setLoading(false);
      return;
    }
    setLoading(true);
    const fetchData = async () => {
      try {
        let data;
        if (selectedQR && queryOptions) {
          data = await countEventsfromBigqueryByTime(
            () => state.user.getIdToken(),
            queryOptions,
            selectedQR
          );
        } else {
          data = await countTotalEventsFromBigqueryByTime(
            () => state.user.getIdToken(),
            queryOptions
          );
          data = [{ data: data.reverse() }];
        }
        setChartData(data);
      } catch (error) {
        // Handle errors here
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    fetchData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedQR, queryOptions]);

  useEffect(() => {
    if (!chartData) return;
    // Create the chart
    Highcharts.chart(chartRef.current, {
      chart: {
        type: "spline",
        zoomType: "x", // Enable zooming on the x-axis
      },
      title: {
        text: "",
      },
      tooltip: {
        animation: true,
        dateTimeLabelFormats: getDateTimeLabelFormats(queryOptions.interval),
      },
      time: {
        useUTC:
          queryOptions.interval === "HOUR" || queryOptions.interval === "MINUTE"
            ? false
            : true,
        timezone: "Europe/Stockholm",
      },
      yAxis: {
        title: {
          text: t("amountScans"), // This will change the title of the yAxis
        },
        accessibility: {
          description: t("amountScans"),
        },
      },
      xAxis: {
        type: "datetime",
        scaleLabel: {
          display: true,
          labelString: "Date",
        },
        accessibility: {
          description: "tidslinje",
        },
      },
      plotOptions: {
        spline: {
          lineWidth: 4,
          states: {
            hover: {
              lineWidth: 5,
            },
          },
          marker: {
            enabled: true,
          },
        },
      },
      series:
        chartData &&
        chartData.map((child) => {
          if (!containData && child.data.length > 0) {
            setContainData(true);
          }
          return {
            name: removeLegend ? "Total" : child.name,
            data: child.data,

            showInLegend: removeLegend ? false : true,
          };
        }),
      accessibility: {
        enabled: true,
      },
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [chartData, windowSize]);

  return (
    <Box>
      <div
        style={{
          display: containData && !loading ? "block" : "none",
          height: "500px",
        }}
        ref={chartRef}
      ></div>
      <Flex
        style={{ display: !containData || loading ? "flex" : "none" }}
        height={"500px"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        {loading ? (
          <Spinner size={"xl"} color={"#062B8E"} />
        ) : (
          <Text color={"black"}>{t("noScansFound")}</Text>
        )}
      </Flex>
    </Box>
  );
};

export default HighChart;
