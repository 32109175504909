import {
  Flex,
  Text,
  Button,
  Divider,
  Center,
  Input,
  Spinner,
  Link,
  InputGroup,
  InputLeftElement,
  InputRightElement,
  Image,
  Box,
} from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { signUpWithEmailAndPassword } from "./index";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";

import { toast } from "react-toastify";
import LoginWithSocial from "./LoginWithSocial";
import ProfileIcon from "../../content/icons/profile.svg";
import { LockIcon } from "@chakra-ui/icons";
import { useTranslation } from "react-i18next";
import LanguageSelector from "../../components/LanguageSelector";

const Create = () => {
  const { t } = useTranslation();

  const navigator = useNavigate();
  const [email, setEmail] = useState();
  const [password, setPassword] = useState();
  const [passwordRepeat, setPasswordRepeat] = useState();
  const [loading, setLoading] = useState(false);

  const [showPasswordRepeat, setShowPasswordRepeat] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  return (
    <Flex p={6} width={"100%"}>
      <Box
        position={"absolute"}
        top={0}
        right={0}
        margin={"20px"}
        background={"white"}
      >
        <LanguageSelector loginPage={true} />
      </Box>
      <Flex flexDirection={"column"} gap={"20px"} width={"100%"}>
        <Flex flexDirection={"column"} gap={"12px"}>
          <Text color={"white"} fontWeight={"bold"} fontSize={"18px"}>
            {t("createAccount")}
          </Text>
          <Text color={"white"} fontSize={"14px"}>
            {t("createAccountSubtext")}{" "}
            <Link href={process.env.REACT_APP_STATIC_STIE} target="_blank">
              {" "}
              {t("termsAndConditions")}
            </Link>
            .
          </Text>
          <Center w={["100%"]}>
            <Divider />
          </Center>
        </Flex>
        <form
          onSubmit={async (e) => {
            e.preventDefault(); // Prevents the default form submission behavior
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

            if (!emailRegex.test(email)) {
              toast.error("Ingen riktig email");
              return;
            }

            if (!password || !passwordRepeat) {
              toast.error("Inget lösenord är angivet.");
              return;
            }

            if (password !== passwordRepeat) {
              toast.error("Lösenorden skiljer sig åt.");
              return;
            }

            if (password === passwordRepeat && emailRegex.test(email)) {
              setLoading(true);
              const response = await signUpWithEmailAndPassword(
                email,
                password
              );
              if (response.status === 200) {
                toast.info(
                  `Ett verifieringsmeddelande har skickats till e-postadressen: ${email}`
                );
                navigator("/login/verify", {
                  state: {
                    email: email,
                    password: password,
                    removeGoogle: true,
                  },
                });
              } else if (response.status === 400) {
                toast.error(response.error);
              }
              setLoading(false);
            }
          }}
        >
          <Flex flexDirection={"column"} gap={"10px"}>
            <InputGroup size="md" width={"100%"}>
              <InputLeftElement
                pointerEvents="none"
                children={
                  <Image width="20px" height={"20px"} src={ProfileIcon} />
                }
              />
              <Input
                bg={"white"}
                fontSize={"14px"}
                border={"1px solid black"}
                rounded={"lg"}
                placeholder={t("email")}
                type="email"
                onChange={(e) => setEmail(e.target.value)}
                _placeholder={{
                  fontWeight: "bold",
                }}
              />
            </InputGroup>
            <InputGroup size="md" width={"100%"}>
              <InputLeftElement
                pointerEvents="none"
                children={<LockIcon color={"#1a202c"} />}
              />
              <InputRightElement
                onClick={() => setShowPassword(!showPassword)}
                cursor={"pointer"}
                children={showPassword ? <ViewIcon /> : <ViewOffIcon />}
              />
              <Input
                bg={"white"}
                fontSize={"14px"}
                border={"1px solid black"}
                rounded={"lg"}
                placeholder={t("password")}
                minLength={8}
                type={showPassword ? "text" : "password"}
                onChange={(e) => setPassword(e.target.value)}
                _placeholder={{
                  fontWeight: "bold",
                }}
              />
            </InputGroup>
            <InputGroup size="md" width={"100%"}>
              <InputLeftElement
                pointerEvents="none"
                children={<LockIcon color={"#1a202c"} />}
              />
              <InputRightElement
                onClick={() => setShowPasswordRepeat(!showPasswordRepeat)}
                cursor={"pointer"}
                children={showPasswordRepeat ? <ViewIcon /> : <ViewOffIcon />}
              />
              <Input
                bg={"white"}
                fontSize={"14px"}
                border={"1px solid black"}
                rounded={"lg"}
                placeholder={t("repeatPassword")}
                minLength={8}
                type={showPasswordRepeat ? "text" : "password"}
                onChange={(e) => setPasswordRepeat(e.target.value)}
                _placeholder={{
                  fontWeight: "bold",
                }}
              />
            </InputGroup>
            <Text
              color={"#718096"}
              fontWeight={"semibold"}
              marginLeft={"auto"}
              fontSize={"12px"}
            >
              {t("passwordRequirement")}
            </Text>
            <Button
              type="submit"
              isDisabled={loading}
              fontSize={"16px"}
              rounded={"3xl"}
              bg={"#5138EE"}
              color={"white"}
              marginTop={"10px"}
              p={6}
              transition={"0.1s"}
              _hover={{ fontSize: "17px" }}
            >
              {loading ? <Spinner /> : <Text> {t("createAccount")} </Text>}
            </Button>
          </Flex>
        </form>
        <LoginWithSocial />
      </Flex>
    </Flex>
  );
};

export default Create;
